import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/form"
import background from "../images/safetylogz-background-small.svg"

const IndexPage = () => (
  <Layout>
    <Background />
    <SEO
      title="Home"
      keywords={[
        `safety`,
        `HSE`,
        `healthy and safety`,
        `safetylogz`,
        `safetylogs`,
        `incident reporting`,
        `safety logging`,
      ]}
    />
    <Container>
      <div>
        <StrapLine>
          Raising the bar on safety logging and incident reporting
        </StrapLine>
        <Form />
      </div>
    </Container>
  </Layout>
)

export default IndexPage

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.45rem 1.0875rem;
  display: flex;
  justify-content: space-between;
  height: 100%;
  @media only screen and (max-width: 414px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media only screen and (min-width: 415px) and (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const Background = styled.div`
  background: unset;

  @media only screen and (min-width: 768px) {
    background: url(${background});
    min-height: 100%;
    min-width: 1024px;

    /* Set up proportionate scaling */
    width: 100%;
    height: auto;

    /* Set up positioning */
    position: fixed;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: left;
  }
`

const StrapLine = styled.p`
  color: #2f4f5d;
  font-size: 30px;
  max-width: 570px;
  margin-top: -35px;
  @media only screen and (max-width: 414px) {
    font-size: 20px;
    width: 100%;
    text-align: center;
    margin-top: -50px;
  }
`
