import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const RegisterForm = () => {
  const industries = [
    "Construction",
    "Consulting",
    "Engineering",
    "Healthcare",
    "Hospitality",
    "Local Government",
    "Manufacturing",
    "Mining or Quarrying",
    "Transportation",
    "Utilities",
    "Other",
  ]

  return (
    <RegisterFormWrapper>
      <form
        name="contact"
        method="post"
        data-netlify="true"
        netlify-honeypot="bot-field"
        action="/success"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <p className="form__title">Register your Interest</p>
        <label for="fullname">
          Name
          <input
            type="text"
            placeholder="Enter your Name"
            name="fullname"
            id="fullname"
            required
          />
        </label>
        <label for="email">
          Email
          <input
            type="email"
            placeholder="Enter your Email"
            name="email"
            id="email"
            required
          />
        </label>
        <label for="company">
          Company Name
          <input
            type="text"
            placeholder="Enter your Company Name"
            name="company"
            id="company"
            required
          />
        </label>
        <label htmlFor="industry_type">Industry</label>
        <select name="industry_type" id="industry_type" required>
          <option disabled selected>
            -- Please Choose --
          </option>
          {industries.map(industry => {
            return <option value={industry}>{industry}</option>
          })}
        </select>
        <div className="radio__wrapper">
          <p>Number of employees</p>
          <div className="radio__group">
            <label className="container">
              1 - 10
              <input
                type="radio"
                name="employee_count"
                value="1-10"
                id="1-10"
                required
              />
              <span className="checkmark"></span>
            </label>
            <label className="container">
              11 - 30
              <input
                type="radio"
                name="employee_count"
                value="11-30"
                id="11-30"
              />
              <span className="checkmark"></span>
            </label>
            <label className="container">
              31 - 75
              <input
                type="radio"
                name="employee_count"
                value="31-75"
                id="31-75"
              />
              <span className="checkmark"></span>
            </label>
            <label className="container">
              {">"} 76
              <input type="radio" name="employee_count" value="76+" id="76+" />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
        <div className="disclaimer">
          <small>
            By submitting this form you agree to us contacting you in the
            future. Read our <Link to="/privacy-policy">privacy policy</Link>{" "}
            for more information.
          </small>
        </div>
        <button>Submit</button>
      </form>
    </RegisterFormWrapper>
  )
}

export default RegisterForm

const RegisterFormWrapper = styled.div`
  font-family: "seido_round";
  font-size: 13px;
  background: #dbe6eb;
  border: 1px solid #2f4f5d;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
  max-width: 490px;
  position: absolute;
  right: 80px;
  top: 119px;
  @media only screen and (min-width: 1400px) {
    right: 210px;
  }
  @media only screen and (max-width: 414px) {
    position: unset;
    padding: 19px 15px;
    margin-bottom: 110px;
  }

  .form__title {
    margin: 10px 0;
    font-size: 20px;
  }

  form {
    margin-bottom: 15px;
  }

  label {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .select__wrapper {
    margin-bottom: 15px;
  }

  input,
  button,
  select {
    height: 35px;
    font-size: 16px;
    margin-bottom: 15px;
    width: 100%;
  }
  input {
    border-radius: 4px;
    border: 1px solid #2f4f5d;
  }

  select {
    font-family: "seido_round";
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: #2f4f5d;
    line-height: 1.3;
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 100%;
    max-width: 100%; /* useful when width is set to anything other than 100% */
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
      linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right 0.7em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.65em auto, 100%;
    option {
      font-family: "seido_round" !important;
      font-feature-settings: "seido_round";
    }
  }

  .radio__wrapper {
    margin: 16px 0 5px;
    p {
      margin: 0;
    }
  }

  .radio__group {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
    .container {
      display: block;
      position: relative;
      padding-left: 25px;
      margin-bottom: 12px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: auto;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 15px;
      width: 15px;
      background-color: #eee;
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background-color: #2f4f5d;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: #2f4f5d;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 4px;
      left: 4px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: white;
    }
  }

  .disclaimer {
    margin-bottom: 5px;
  }
  button {
    background: #2f4f5d;
    border: 1px solid #2f4f5d;
    border-radius: 4px;
    color: #ffffff;
    letter-spacing: 1px;
    height: 45px;
    margin: 0;
  }
`
